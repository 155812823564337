import { createSlice } from "@reduxjs/toolkit";
import { answerQuestion, fetchUnansweredQuestions, loginAdmin, rejectQuestion } from '../actions/faqAdminActions';

interface UnansweredQuestion {
  _id: string
  text: string
  userFio: string
  //userName: string
  userEmail: string
}

interface AnsweredQuestion {
  _id: string
  text: string
  userFio: string
  //question: string
  //userName: string
  answerDate: string
  direction: string
  doctorFio: string
  answer: string
}

interface FaqAdminState {
  loading: boolean;
  isAuthenticated: boolean;
  error: string | null;
  unansweredQuestions: UnansweredQuestion[];
  answeredQuestions: AnsweredQuestion[]
}

const initialState: FaqAdminState = {
  loading: false,
  isAuthenticated: false,
  error: null,
  unansweredQuestions: [],
  answeredQuestions: []
};

const faqAdminSlice = createSlice({
  name: "faqAdmin",
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.error = null;
      localStorage.removeItem("admin-token"); // Удаление токена
    },
    setToken: (state, { payload }) => {
      state.isAuthenticated = true;
      state.error = null;
      localStorage.setItem("admin-token", payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
      })
      .addCase(loginAdmin.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = action.payload as string;
      })
      .addCase(fetchUnansweredQuestions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUnansweredQuestions.fulfilled, (state, action) => {
        state.loading = false;
        state.unansweredQuestions = action.payload; // Сохраняем вопросы
      })
      .addCase(fetchUnansweredQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(answerQuestion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(answerQuestion.fulfilled, (state, action) => {
        state.loading = false;
        state.unansweredQuestions = state.unansweredQuestions.filter(q => q._id !== action.payload._id);
      })
      .addCase(answerQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(rejectQuestion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(rejectQuestion.fulfilled, (state, action) => {
        state.loading = false;
        state.unansweredQuestions = state.unansweredQuestions.filter(q => q._id !== action.payload._id);
      })
      .addCase(rejectQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { logout, setToken } = faqAdminSlice.actions
export const faqAdminReducer = faqAdminSlice.reducer;

export type { UnansweredQuestion }
