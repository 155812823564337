import React from 'react';
import Img_desktop from "../assets/images/single-img/desctop/childrens.webp";
import Img_mobile from "../assets/images/single-img/mobile/children_faq.webp";
import Img_tablet from "../assets/images/single-img/tablet/childrens.webp";
import Banner_desktop from "../assets/images/single-img/aboutPage/desktop/d-banner.webp";
import Banner_tablet from "../assets/images/single-img/aboutPage/tablet/t-banner.webp";
import Banner_mobile from "../assets/images/single-img/aboutPage/mobile/m-banner.webp";

import Img_structure_1_d from "../assets/images/single-img/aboutPage/desktop/d-clinic-1.webp";
import Img_structure_2_d from "../assets/images/single-img/aboutPage/desktop/d-clinic-2.webp";
import Img_structure_3_d from "../assets/images/single-img/aboutPage/desktop/d-clinic-3.webp";
import Img_structure_4_d from "../assets/images/single-img/aboutPage/desktop/d-clinic-4.webp";
import Img_structure_5_d from "../assets/images/single-img/aboutPage/desktop/d-clinic-5.webp";
import Img_success_1_d from "../assets/images/single-img/aboutPage/desktop/d-clinic-6.webp";
import Img_success_2_d from "../assets/images/single-img/aboutPage/desktop/d-clinic-7.webp";
import Img_success_3_d from "../assets/images/single-img/aboutPage/desktop/d-clinic-8.webp";
import Img_success_4_d from "../assets/images/single-img/aboutPage/desktop/d-clinic-9.webp";
import Img_success_5_d from "../assets/images/single-img/aboutPage/desktop/d-clinic-10.webp";

import Img_structure_1_m from "../assets/images/single-img/aboutPage/mobile/m-clinic-1.webp";
import Img_structure_2_m from "../assets/images/single-img/aboutPage/mobile/m-clinic-2.webp";
import Img_structure_3_m from "../assets/images/single-img/aboutPage/mobile/m-clinic-3.webp";
import Img_structure_4_m from "../assets/images/single-img/aboutPage/mobile/m-clinic-4.webp";
import Img_structure_5_m from "../assets/images/single-img/aboutPage/mobile/m-clinic-5.webp";
import Img_success_1_m from "../assets/images/single-img/aboutPage/mobile/m-clinic-6.webp";
import Img_success_2_m from "../assets/images/single-img/aboutPage/mobile/m-clinic-7.webp";
import Img_success_3_m from "../assets/images/single-img/aboutPage/mobile/m-clinic-8.webp";
import Img_success_4_m from "../assets/images/single-img/aboutPage/mobile/m-clinic-9.webp";
import Img_success_5_m from "../assets/images/single-img/aboutPage/mobile/m-clinic-10.webp";

import posterSrc from "../assets/images/single-img/aboutPage/prev-video-desktop.webp";
//import src from "../assets/video/about_video.mp4";
import lic1 from '../assets/images/single-img/aboutPage/licenziya-1.webp';
import lic2 from '../assets/images/single-img/aboutPage/licenziya-2.webp';
import lic3 from '../assets/images/single-img/aboutPage/licenziya-3.webp';
import lic4 from '../assets/images/single-img/aboutPage/licenziya-4.webp';
import lic5 from '../assets/images/single-img/aboutPage/licenziya-5.webp';


const initialState = {   
    pageTopPart: {
        pageName: "about",
        srcDesktop: Img_desktop,            
        srcMobile: Img_mobile,
        srcTablet: Img_tablet,
        title: "О клинике"       
    }, 
    content: {
        first_block: {
            d_banner: Banner_desktop,
            t_banner: Banner_tablet,
            m_banner: Banner_mobile
        },
        second_block: [
            {
                id: 1,
                value: "1000",
                text: "Циклов проведенных ЭКО в год"
            },
            {
                id: 2,
                value: "200",
                text: "Человек в день можем принять"
            },
            {
                id: 3,
                value: ">50 000",
                text: "Человек - база пациентов"
            },
            {
                id: 4,
                value: ">4000",
                text: "Проведено программ ВРТ. Результативность лечения 45% (средняя по стране 37%)"
            },
            {
                id: 5,
                value: ">3000",
                text: "Бесплатных ЭКО за счет средств ОМС"
            },
            {
                id: 6,
                value: ">1000",
                text: "Детей уже родилось в стенах наших клиник"
            }
        ],
        structure_block: [
            {
                id: 1,
                img_d: Img_structure_1_d,  
                img_m: Img_structure_1_m,              
                text: "Консультативно-диагностическое отделение"
            },
            {
                id: 2,
                img_d: Img_structure_2_d, 
                img_m: Img_structure_2_m,               
                text: "Отделение и лаборатория ВРТ (вспомогательные репродуктивные технологии)"
            },
            {
                id: 3,
                img_d: Img_structure_3_d,
                img_m: Img_structure_3_m,                
                text: "Малая операционная"
            },
            {
                id: 4,
                img_d: Img_structure_4_d, 
                img_m: Img_structure_4_m,               
                text: "Дневной стационар по профилю «Травматология-ортопедия»"
            },
            {
                id: 5,
                img_d: Img_structure_5_d,
                img_m: Img_structure_5_m,                
                text: "Криохранилище"
            },
        ],
        success_components: [
            {
                id: 1,
                img_d: Img_success_1_d,
                img_m: Img_success_1_m,
                title: "Профессионализм наших врачей и эмбриологов",
                text:  `<ul><li>Все они являются членами Российской, ассоциации репродукции человека (РАРЧ).</li><li>Ежегодно посещают международные тематические конгрессы и крупнейшие специализированные мероприятия.</li><li>В своей практике используют передовые мировые тенденции лечения бесплодия.</li></ul>`,
                btn: {
                    text: "Наши врачи",
                    url: "doctors"
                }                
            },
            {
                id: 2,
                img_d: Img_success_2_d,
                img_m: Img_success_2_m,
                title: "Опыт",
                text:  `<ul><li>Мы – первая клиника в регионе, которая бросила вызов бесплодию и успешно провела оплодотворение in vitro  в 2013 году.</li><li>"Нашими" мы можем с гордостью назвать более 1000 малышей.</li></ul>`                              
            },
            {
                id: 3,
                img_d: Img_success_3_d,
                img_m: Img_success_3_m,
                title: "Клиенториентированность",
                text:  `<span>С момента, когда вы впервые попадаете в клинику, и до желаемого момента рождения ребенка может пройти значительно больше 9 месяцев. В этот период ваши визиты в клинику будут регулярными. Мы хотим, чтобы вы чувствовали себя комфортно и чувствовали тепло от визитов, общения с персоналом, атмосферы в клинике. Понимание, участие, забота – это то, на что вы точно можете рассчитывать.</span>`                              
            },
            {
                id: 4,
                img_d: Img_success_4_d,
                img_m: Img_success_4_m,
                title: "Уникальное современное оборудование",
                text:  `<ul><li>Воплощение последних достижений медицинской технической мысли.</li><li>Большая часть оборудования не имеет аналогов в Ульяновской области.</li></ul>`                              
            },
            {
                id: 5,
                img_d: Img_success_5_d,
                img_m: Img_success_5_m,
                title: "Доверие наших пациентов",
                text:  `<span>Именно оно становится той движущей силой, которая помогает нам достигать высоких результатов в лечении мужского и женского бесплодия.</span>`,
                btn: {
                    text: "Отзывы о нас",
                    url: "reviews"
                }                
            }
        ],
        maps: {
            tabs: [
                {
                    title: "Новый город (пр-т Врача Сурова, д.4)",
                    status: "surova"
                },
                {
                    title: "Центр (ул. Бебеля, 1А)",
                    status: "bebelya"
                },
                {
                    title: "Засвияжье (ул. Рябикова, 96)",
                    status: "ryabikova"
                }
            ]            
        },
        video: {
            poster: posterSrc,
            //src: src
        },
        licenses: [lic1, lic2, lic3, lic4, lic5] 
    }
    
}

export type initialStateType = typeof initialState;

export const aboutReducer = (state = initialState, action: any):initialStateType => {
    return state;    
};