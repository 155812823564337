import React from 'react';
import { styled } from 'styled-components';
import { StyledH2 } from '../../../../components/StyledH2';
import { StyledOrangeLink } from '../../../../components/StyledBtn';
import { PreviewLastNews } from './PreviewLastNews';
import { LastNewsPropsType } from './LastNews';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';



export const TabletLastNews: React.FC<LastNewsPropsType> = (props: LastNewsPropsType) => {
    
    return (
        <StyledTabletLastNews className="bulletLocation_news">            
                <StyledH2><span>{props.title}</span></StyledH2>
            <WrapSlider>                 
                <Swiper 
                    pagination={true} 
                    modules={[Pagination]}
                    grabCursor={true}                                
                    slidesPerView={'auto'}
                    spaceBetween={18}
                    //loop={true} 
                    initialSlide={0}
                    className="mySwiper"
                >                   
                    {
                        props.lastNews.map((news) => {
                            return (
                                <SwiperSlide key={news.id}>
                                    <PreviewLastNews 
                                        news={news}
                                        fontSize={props.fontSize}
                                    />
                                </SwiperSlide>
                            );                        
                        })
                    }                     
                </Swiper>                
            </WrapSlider>
            <StyledOrangeLink to="/news">Все новости</StyledOrangeLink>
                                        
        </StyledTabletLastNews>
    );
};

const StyledTabletLastNews = styled.section`
    padding: 20px 0;    
`

const WrapSlider = styled.div`
    margin-bottom: 18px;   
`
