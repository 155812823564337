import { createSlice } from '@reduxjs/toolkit';
import { createQuestion, fetchAnsweredQuestions } from '../actions/faqActions';

interface AnsweredQuestion {
  _id: string
  text: string
  userFio: string
  //question: string
  //userName: string
  answerDate: string
  direction: string
  doctorFio: string
  answer: string
}

interface FaqState {
  loading: boolean;
  error: string | null;
  answeredQuestions: AnsweredQuestion[]
}

const initialState: FaqState = {
  loading: false,
  error: null,
  answeredQuestions: []
};

const faqSlice = createSlice({
  name: "faqAdmin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnsweredQuestions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAnsweredQuestions.fulfilled, (state, action) => {
        state.loading = false;
        state.answeredQuestions = action.payload;
      })
      .addCase(fetchAnsweredQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createQuestion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createQuestion.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  }
})

export const faqReducer = faqSlice.reducer;


export type { AnsweredQuestion }



