import { useEffect, useState } from "react";
import { initialFontSize } from "./useCurrentFontSize";
import { Theme } from "./useCurrentTheme";


const initialState = {show: false, translateY: "-58px"};

export const useToggleVisuallyImpairedPanel = (themeName:Theme, setThemeName:Function, setFontSize:Function) => {

    const [visuallyImpairedPanel, setVisuallyImpairedPanel] = useState(initialState); 

    const handleVisuallyImpairedPanel = () => {        
    
        if(!visuallyImpairedPanel.show){
            window.localStorage.setItem("translateY", "0");
            window.localStorage.setItem("show", "true");
            setVisuallyImpairedPanel({
                ...visuallyImpairedPanel,
                show: true,
                translateY: "0"
            });
        }
    
        if(visuallyImpairedPanel.show) {
    
            if(themeName === "default") {
                window.localStorage.setItem("translateY", "-58px");
                window.localStorage.setItem("show", "false");
                window.localStorage.fontSize = initialFontSize;
            }
            else {
                window.localStorage.clear();
                setThemeName("default");
            }
            setFontSize(initialFontSize);
            setVisuallyImpairedPanel({
                ...visuallyImpairedPanel,
                show: false,
                translateY: "-58px"
            });
        }        
      }

      useEffect(() => { 
          const offset = window.localStorage.getItem('translateY');
          const panel = window.localStorage.getItem('show') === "true" ? true : false;
          offset && setVisuallyImpairedPanel({...visuallyImpairedPanel, translateY: offset, show: panel});
        }, []);
    
    return { visuallyImpairedPanel, handleVisuallyImpairedPanel }

}