import React from 'react';
import { DesctopDoctors } from './DesctopDoctors';
import { LaptopDoctors } from './LaptopDoctors';
import { TabletDoctors } from './TabletDoctors';
import { WindowSize } from '../../../../hooks/WindowSize';
import { useSelector } from 'react-redux';
import { doctorsSelector } from '../../../../selectors/selectors';

type DoctorsPropsType = {
    // doctors: Array<{
    //     fullName:string
    //     post:string
    //     workExperience:string
    //     foto:string
    //     path:string
    //     }>
    fontSize:number
}

export const Doctors: React.FC<DoctorsPropsType> = (props: DoctorsPropsType) => {
    const width = WindowSize();    
    const laptop = 1200;
    const tablet = 767;
    const doctors = useSelector(doctorsSelector).slice(0, 4);

    return (
        <>
            {width > tablet 
            ? (width > laptop 
                ? <DesctopDoctors doctors={doctors} fontSize={props.fontSize} />
                : <LaptopDoctors doctors={doctors} fontSize={props.fontSize} />
            )         
            : <TabletDoctors doctors={doctors} fontSize={props.fontSize}/>        
            }
        </>
    );
};



//const [width, setWidth] = useState(window.innerWidth);
// useEffect(() => {    
    //     const handleWindowResize = () => setWidth(window.innerWidth);

    //     window.addEventListener('resize', handleWindowResize);

    //     return () => {
    //         window.removeEventListener('resize', handleWindowResize);
    //     }
    // }, []);