import { useEffect, useLayoutEffect } from "react"
import { useLocation } from "react-router-dom";



export const ScrollTop = () => {

    const {pathname} = useLocation();
    // useEffect(() => {
    //   window.scrollTo({
    //     top: 0,
    //     left: 0,
    //     //behavior : 'instant' 
    //   })
    // }, [pathname])
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname])
  
    return null;
}
  
