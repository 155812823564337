import { createAsyncThunk } from "@reduxjs/toolkit";
import { logout, setToken } from '../slices/faqAdminSlice';
import {API_URL} from "../../config";


export const loginAdmin = createAsyncThunk(
  "auth/loginAdmin",
  async ({ login, password }: { login: string; password: string }, thunkAPI) => {
    try {
      const response = await fetch(API_URL+"/api/v1/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user: { login, password } }),
      });

      if (!response.ok) {
        throw new Error("Ошибка сервера");
      }

      const data = await response.json();

      if (data.success) {
        console.log('success ', data);
        localStorage.setItem('admin-token', data?.access_token);

        return data; // Успешный результат
      } else {
        return thunkAPI.rejectWithValue(data.message); // Обработка ошибки
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message || "Ошибка сети");
    }
  }
);


export const fetchUnansweredQuestions = createAsyncThunk(
  "faq/fetchUnansweredQuestions",
  async (_, { dispatch, rejectWithValue }) => {
    const token = localStorage.getItem("admin-token");

    // Проверяем токен
    if (!token) {
      // Если токен не валиден, инициируем авторизацию
      const login = prompt("Login:");
      if (!login?.trim()) return
      const password = prompt("Password:");
      if (!login?.trim() || !password?.trim()) return rejectWithValue("Authorization failed");
      await dispatch(loginAdmin({ login, password }));
    } else {
      dispatch(setToken(token))
    }

    // Загружаем вопросы
    try {
      const response = await fetch(API_URL+"/api/v1/questions/unanswered", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("admin-token")}`,
        },
      });

      if (response.status === 401) {
        dispatch(logout());
        window.location.reload();
        return rejectWithValue("Unauthorized");
      }

      if (!response.ok) throw new Error("Failed to fetch questions");
      const data = await response.json();
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);



export const answerQuestion = createAsyncThunk(
  "faq/approveQuestion",
  async (params: {id: string, answer: string, direction: string, doctorFio: string }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_URL}/api/v1/questions/answer/${params.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("admin-token")}`
        },
        body: JSON.stringify({
          answer: params.answer,
          direction: params.direction,
          doctorFio: params.doctorFio
        }),
      });

      if (!response.ok) throw new Error("Failed to approve question");
      const data = await response.json();
      return { _id: params.id };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const rejectQuestion = createAsyncThunk(
  "faq/rejectQuestion",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_URL}/api/v1/questions/reject/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("admin-token")}`
        },
      });

      if (!response.ok) throw new Error("Failed to reject question");
      const data = await response.json();
      return { _id: id };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);