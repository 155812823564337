import { createAsyncThunk } from '@reduxjs/toolkit';
import {API_URL} from "../../config";


export const fetchAnsweredQuestions = createAsyncThunk(
  "faq/fetchAnsweredQuestions",
  async (_, { dispatch, rejectWithValue }) => {

    try {
      const response = await fetch(API_URL+"/api/v1/questions/answered", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });


      if (!response.ok) throw new Error("Failed to fetch questions");
      const data = await response.json();
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const createQuestion = createAsyncThunk(
  "faq/createQuestion",
  async (questionData: { userFio: string; userEmail: string; text: string; }, { rejectWithValue }) => {
    try {
      const response = await fetch(API_URL + "/api/v1/questions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(questionData),
      });

      if (!response.ok) throw new Error("Failed to create question");
      const data = await response.json();
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

