import React from 'react';
import { styled } from 'styled-components';

import { useSelector } from 'react-redux';
import { ScrollTop } from '../../../../components/ScrollTop';
import { Container } from '../../../../components/Container';
import { StyledH2 } from '../../../../components/StyledH2';
import { newsPageSelector } from '../../../../selectors/selectors';
import { LastNews } from '../../../../layout/section/homepage/last_news/LastNews';
import { BreadСrumbs } from '../../../../components/bread-crumbs/BreadCrumbs';
import { StyledH1 } from '../../../../components/StyledH1';



type CurrentNewsPropsType = {
    id:number    
    fontSize:number    
}

export const News_9: React.FC<CurrentNewsPropsType> = (props: CurrentNewsPropsType) => {

    const news = useSelector(newsPageSelector);

    const data = news.newsList.filter(obj => obj.id === props.id)[0];
    const lastNews = news.newsList.filter(news => news.id !== props.id).slice(0, 4);
    
    return (
        <StyledNews_9>
            <ScrollTop />
            <Wrap>
                <Container>
                    <BreadСrumbs />
                    <StyledH1>{data.title}</StyledH1>
                    <Data>{data.data}</Data>
                </Container>
            </Wrap>       
            <Container>               
               <Content>                    
                    <Picture>
                        <source media="(max-width: 576px)" srcSet={data.innerImgM} />
                        <source media="(min-width: 768px)" srcSet={data.innerImgD} />
                        <img src={data.innerImgT} alt="" />
                    </Picture>
                    <Text
                        dangerouslySetInnerHTML={{ __html: data.content}}
                    >
                    </Text>
               </Content>            
                <LastNews 
                    lastNews={lastNews} 
                    fontSize={props.fontSize} 
                    title="Читайте также"
                />
            </Container>
        </StyledNews_9>
    );
};


const StyledNews_9 = styled.div`
    ${StyledH2}{
        text-align: left;
    }    
`
const Wrap = styled.div`
    height: auto;
    background-color: ${({theme}) => theme.bgCol.pageName.news};
    padding: 52px 0 36px;

    ${StyledH1}{
        margin-top: 26px;
    }
`
const Data = styled.div`
    margin-top: 25px;
    color: ${({theme}) => theme.color.multiСhannel};
    font-size: 1.14rem;
`

const Content = styled.div`
    display: flex;
    gap: 20px;
    margin-bottom: 50px;
    padding-top: 36px;

    @media ${({theme}) => theme.media.tablet}{
        flex-direction: column;
        align-items: center;
        margin-bottom: 25px;
        padding-top: 22px;
    }
    @media ${({theme}) => theme.media.mobile}{
        padding-top: 8px;
    }
`
const Picture = styled.picture` 
    width: 50%;
    
    overflow: hidden;
    img {
        max-width: 100%;
        height: auto;
        border-radius: 24px; 
    }
    @media ${({theme}) => theme.media.tablet}{
        width: 100%;          
    }  

`
const Text = styled.div`
    width: auto;

    p{
        margin-bottom: 10px;
        font-size: calc((100vw - 26rem)/(137 - 26) * (1.14 - 1) + 1rem);
        line-height: 140%;
        font-weight: 500;
    }
`

// const Wrap = styled.div`
//     display: flex;
//     justify-content: space-between;
//     align-items: center; 
//     width: 100%;
//     height: 60px;
//     margin-bottom: 40px; 
    
//     ${StyledH2} {
//         margin-bottom: 0;
//     }
// `


// const NewsList = styled.ul`    
//     display: grid;
//     grid-template-columns: repeat(auto-fit, 304px);
//     justify-content: center;
//     gap: 20px;  
//     margin: 20px 0 100px;
    
//     @media ${({theme}) => theme.media.tablet} {
//       grid-template-columns: repeat(auto-fit, 224px); 
//     }
// `