import { useEffect, useState } from "react";


export type Theme = "default" | "light" | "dark" | "blue" ;

export const useCurrentTheme = () => {
    const [themeName, setThemeName] = useState<Theme>("default");

    function handleToggleTheme(e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>){    
        if(e.currentTarget.name === "light" && themeName !== "light") {
          window.localStorage.setItem("themeName", "light");
          setThemeName("light");
        }
        if(e.currentTarget.name === "dark" && themeName !== "dark") {
          window.localStorage.setItem("themeName", "dark");
          setThemeName("dark");
        }
        if(e.currentTarget.name === "blue" && themeName !== "blue") {
          window.localStorage.setItem("themeName", "blue");
          setThemeName("blue");
        }
        if(!e.currentTarget.name){
          window.localStorage.setItem("themeName", "default");
          setThemeName("default");
        }    
      }    
      
      // Устанавливаем тему из локального хранилища
      useEffect(() => {
        const localTheme = window.localStorage.getItem("themeName");
        localTheme && setThemeName(localTheme as Theme);
        }, []);
    
    return {themeName, setThemeName, handleToggleTheme};
}