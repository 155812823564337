import React from 'react';
import Img_desktop from "../assets/images/single-img/desctop/childrens_news_d.webp";
import Img_tablet from "../assets/images/single-img/tablet/childrens_news_t.webp";
import Img_mobile from "../assets/images/single-img/mobile/children_news_m.webp";


import Img_news_1 from "../assets/images/single-img/news/preview/news-1.webp";
import Img_news_2 from "../assets/images/single-img/news/preview/news-2.webp";
import Img_news_3 from "../assets/images/single-img/news/preview/news-3.webp";
import Img_news_4 from "../assets/images/single-img/news/preview/news-4.webp";
import Img_news_5 from "../assets/images/single-img/news/preview/news-5.webp";
import Img_news_6 from "../assets/images/single-img/news/preview/news-6.webp";
import Img_news_7 from "../assets/images/single-img/news/preview/news-7.webp";
import Img_news_8 from "../assets/images/single-img/news/preview/news-8.webp";
import Img_news_9 from "../assets/images/single-img/news/preview/news-9.webp";

import Img_inner_9_1_d from "../assets/images/single-img/news/current-news/news_9/news-9-d.webp";
import Img_inner_9_1_t from "../assets/images/single-img/news/current-news/news_9/news-9-t.webp";
import Img_inner_9_1_m from "../assets/images/single-img/news/current-news/news_9/news-9-m.webp";



const initialState = {   
    pageTopPart: {
        pageName: "news",
        srcDesktop: Img_desktop,            
        srcMobile: Img_mobile,
        srcTablet: Img_tablet,
        title: "Новости клиники"       
    }, 

    years: [
        "Все года",
        "2024",
        "2023"        
    ],
    
    newsList: [
        {
            id: 9,
            img: Img_news_9,
            title: 'Изменение цен с 1 января 2025 года',
            data: '16.12.2024',
            prev: 'Уважаемые пациенты! Сообщаем вам о том, что с 1 января 2025 года в медицинском центре "Альянс Клиник" произойдет повышение цен.',
            path: "/news/news_9",
            innerImgD: Img_inner_9_1_d,
            innerImgT: Img_inner_9_1_t,
            innerImgM: Img_inner_9_1_m,
            content: `<p>Уважаемые пациенты! Сообщаем вам о том, что с 1 января 2025 года в медицинском центре "Альянс Клиник" произойдет повышение цен.</p><p>При этом мы продолжим вводить новые акции с дополнительными скидками и выгодными предложениями.</p><p>Благодарим за понимание.</p>`
        },
        {
            id: 8,
            img: Img_news_8,
            title: 'Поздравляем с победой в Премии ПроДокторов 2024!',
            data: '02.11.2024',
            prev: ' ',
            path: '/news/news_8',
            innerImgD: Img_inner_9_1_d,
            content:`<p></p>`
        },
        {
            id: 7,
            img: Img_news_7,
            title: 'Микрофлюидика в ЭКО',
            data: '28.08.2024',
            prev: 'Микрофлюидика как метод для подготовки и отбора сперматозоидов для экстракорпорального оплодотворения (ЭКО) представляет собой ...',
            path: '/news/news_7',
            innerImgD: Img_inner_9_1_d,
            content:`<p></p>`
        },
        {
            id: 6,
            img: Img_news_6,
            title: '«Альянс Клиник» скоро примет своего 50-тысячного пациента!',
            data: '08.08.2024',
            prev: 'Уважаемые пациенты, мы рады объявить о том, что по нашей статистике, клиника репродуктивного здоровья «Альянс Клиник» скоро примет своего 50-тысячного пациента!',
            path: '/news/news_6',
            innerImgD: Img_inner_9_1_d,
            content:`<p></p>`

        },
        {
            id: 5,
            img: Img_news_5,
            title: 'С 2024 года артроскопию коленного сустава наши пациенты смогут пройти бесплатно, по полису ОМС',
            data: '22.01.2024',
            prev: 'Друзья, мы рады сообщить отличную новость! С 2024 года артроскопию коленного сустава наши пациенты смогут пройти бесплатно, по полису ОМС.',
            path: '/news/news_5',
            innerImgD: Img_inner_9_1_d,
            content:`<p></p>`
        },
        {
            id: 4,
            img: Img_news_4,
            title: 'Планируете криоперенос? Тогда это предложение для вас!',
            data: '16.11.2023',
            prev: 'Уважаемые пациенты! В связи с повышением цен с 1 января 2024 года, на ряд услуг ВРТ, ограниченным количеством данных услуг в рамках ОМС ...',
            path: '/news/news_4',
            innerImgD: Img_inner_9_1_d,
            content:`<p></p>`
        },
        {
            id: 3,
            img: Img_news_3,
            title: 'Артроскопия коленного сустава с экономией от 5000 до 7000 рублей!',
            data: '15.06.2023',
            prev: 'Испытываете боли в коленях? Трудно ходить и вставать? Старые травмы и растяжения не дают покоя? Вашим суставам нужна помощь!',
            path: '/news/news_3',
            innerImgD: Img_inner_9_1_d,
            content:`<p></p>`
        },
        {
            id: 2,
            img: Img_news_2,
            title: 'С Днем Великой Победы!',
            data: '05.05.2023',
            prev: 'День победы - значимый и волнующий для каждого жителя нашей страны праздник, который мы отмечаем как дань памяти и глубокого уважения ...',
            path: '/news/news_2',
            innerImgD: Img_inner_9_1_d,
            content:`<p></p>` 
        },
        {
            id: 1,
            img: Img_news_1,
            title: 'ВАЖНО! Информация для пациентов!',
            data: '20.02.2023',
            prev: 'Уважаемые, пациенты. Доводим до вашего сведения, что с 1 марта изменился режим работы клиники по адресу пр-т врача Сурова,4. Теперь мы работаем для вас ежедневно без выходных.',
            path: '/news/news_1',
            innerImgD: Img_inner_9_1_d,
            content:`<p></p>`
        }
    ],
    step: 8    
}

export type initialStateType = typeof initialState;

export const newsReducer = (state = initialState, action: any):initialStateType => {   

    return state;    
};

