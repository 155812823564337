import React from 'react';
import { styled } from 'styled-components';
import { StyledH2 } from '../../../../components/StyledH2';
import { StyledOrangeLink } from '../../../../components/StyledBtn';
import { PreviewLastNews } from './PreviewLastNews';
import { LastNewsPropsType } from './LastNews';


export const DesktopLastNews: React.FC<LastNewsPropsType> = (props: LastNewsPropsType) => {
        
    return (
        <StyledDesktopLastNews>
            <Wrap>
                <StyledH2><span>{props.title}</span></StyledH2>
                <StyledOrangeLink to="/news">Все новости</StyledOrangeLink>
            </Wrap>
            <LastNewsList>
                {
                    props.lastNews.map((news) => {
                        return (
                            <PreviewLastNews 
                                key={news.id}
                                news={news}
                                fontSize={props.fontSize}
                            />
                        );                        
                    })
                }
            </LastNewsList>                                        
        </StyledDesktopLastNews>
    );
};

const StyledDesktopLastNews = styled.section`
  padding: 50px 0;  
`
const Wrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center; 
    width: 100%;
    height: 60px;
    margin-bottom: 40px; 
    
    ${StyledH2} {
        margin-bottom: 0;
    }
`

const LastNewsList = styled.ul`
    display: flex;    
    justify-content: space-between;
    gap: 20px;    
`
