import { useEffect, useState } from "react";


export const initialFontSize = 14;

export const useCurrentFontSize = () => {

    const [fontSize, setFontSize] = useState<number>(initialFontSize);

    function handleFontSize(e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>){

        if(e.currentTarget.name === "decrease" && fontSize > 12) {

            window.localStorage.setItem("fontSize", `${fontSize - 1}`); 
            setFontSize((prev) => prev - 1);
        }
    
        if(e.currentTarget.name === "add" && fontSize < 26) {      
          
            window.localStorage.setItem("fontSize", `${fontSize + 1}`);
            setFontSize((prev) => prev + 1);
        }      
    }

    // Устанавливаем размер шрифта равным значению из локального хранилища
      useEffect(() => {
        const localFontSize = window.localStorage.getItem("fontSize");
        localFontSize && setFontSize(+localFontSize);
      }, []);
    
    return { fontSize, setFontSize, handleFontSize };
}