import React, { useState } from 'react';
import { styled } from 'styled-components';
import { MobileHeader } from './mobileHeader/MobileHeader';
import { DesktopHeader } from './desktopHeader/DesktopHeader';
import { initialStateSidebarType } from '../../redux/sidebarReducer';

type HeaderPropsType = {
    // mainMenu: Array<{ancor:string, path:string}>
    // subMenu: Array<{ancor:string, path:string}>
    // counter: {
    //     boys: number,
    //     girls: number         
    // }    
    // contacts: {
    //     phone1: string
    //     phone2: string
    //     email: string
    //     callback:boolean
    // }
    // socials: Array<{id:string, link:string}>
    
    handleToggleTheme: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
    handleFontSize: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
    themeName: string
    setThemeName: Function    
    fontSize: number    
    visuallyImpairedPanel: {show:boolean, translateY:string}
    handleVisuallyImpairedPanel: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void    
    handleToggleSidebar: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
    handleToggleModalWindow: (windowName:string) => void    
    sidebar: initialStateSidebarType
    windowWidth:number
    isOpenMobileMenuContact:boolean       
}


export const Header: React.FC<HeaderPropsType> = (props: HeaderPropsType) => {
    
    const breakpoint = 767;
    
    const [isActiveId, setIsActiveId] = useState(null);
    const [isOpenMenu, setIsOpenNenu] = useState(false);    
    const handleCloseModalWindowMenu = () => {
        setIsOpenNenu(prev => !prev);        
    };
    const handleBurgerBtnClick = () => {
        setIsOpenNenu(prev => !prev);
    } 

    return (
        <StyledHeader>            
            {props.windowWidth > breakpoint 
            ? <DesktopHeader 
                // mainMenu={header.headerMenu.mainMenu}
                // subMenu={header.headerMenu.subMenu}
                // contacts={header.contacts}
                // counter={header.counter}
                // socials={header.socials}          
                handleToggleTheme={props.handleToggleTheme}
                handleFontSize={props.handleFontSize}
                themeName={props.themeName}
                //setThemeName={props.setThemeName}                
                fontSize={props.fontSize}                
                handleVisuallyImpairedPanel={props.handleVisuallyImpairedPanel}
                visuallyImpairedPanel={props.visuallyImpairedPanel}
                handleToggleSidebar={props.handleToggleSidebar} 
                handleToggleModalWindow={props.handleToggleModalWindow}
                breakpoint={breakpoint}   
                windowWidth={props.windowWidth}
                sidebar={props.sidebar} 
                isActiveId={isActiveId}
                setIsActiveId={setIsActiveId}
                isOpenMenu={isOpenMenu}
                setIsOpenNenu={setIsOpenNenu}
                handleCloseModalWindowMenu={handleCloseModalWindowMenu} 
                handleBurgerBtnClick={handleBurgerBtnClick}                           
            /> 
            : <MobileHeader 
                // counter={header.counter}
                // socials={header.socials} 
                // contacts={header.contacts}         
                handleToggleTheme={props.handleToggleTheme}
                handleFontSize={props.handleFontSize}
                themeName={props.themeName}                
                handleVisuallyImpairedPanel={props.handleVisuallyImpairedPanel}
                visuallyImpairedPanel={props.visuallyImpairedPanel}                 
                handleToggleModalWindow={props.handleToggleModalWindow}  
                breakpoint={breakpoint}   
                windowWidth={props.windowWidth}  
                sidebar={props.sidebar}
                isActiveId={isActiveId}
                setIsActiveId={setIsActiveId}  
                isOpenMenu={isOpenMenu}
                setIsOpenNenu={setIsOpenNenu}
                handleCloseModalWindowMenu={handleCloseModalWindowMenu} 
                handleBurgerBtnClick={handleBurgerBtnClick}
                isOpenMobileMenuContact={props.isOpenMobileMenuContact}                              
            />}
        </StyledHeader> 
    );
};

const StyledHeader = styled.div` 
    
`

