import React, { lazy, Suspense } from 'react';
import { GlobalStyles } from './styles/GlobalStyles';
import styled, { ThemeProvider } from 'styled-components';
import { myTheme } from './styles/Theme.styled';
import { Header } from './layout/header/Header';
//import { HeaderNew } from './layout/header/Header';
import { useEffect, useState } from 'react';
//import { FontSizeContext } from './context/FontSizeContext';
import { Route, Routes } from 'react-router-dom';
//import { Prices } from './pages/main/prices/Prices';

//import { AllDoctors } from './pages/main/AllDoctors';
//import { Timetable } from './pages/main/Timetable';
import { Eco } from './pages/main/Eco';
//import { Faq } from './pages/main/Faq';
import { Contacts } from './pages/main/Contacts';
import { About } from './pages/main/about/About';
//import { News } from './pages/main/News';
import { Questionnaire } from './pages/main/Questionnaire';
import { Documents } from './pages/main/Documents';
import { Vacancy } from './pages/main/Vacancy';
import { Reception } from './pages/main/Reception';
//import { Tour } from './pages/main/Tour';
import { PaymentTerms } from './pages/main/PaymentTerms';
import { Home } from './pages/main/Home';
import UsefulInformation from './pages/main/UsefulInformation';
import { Footer } from './layout/footer/Footer';
import { Sidebar } from './layout/sidebar/Sidebar';
import { Overlay } from './components/Overlay';
import { ModalWindowRecord } from './components/pop-up/ModalWindowRecord';
import { ModalWindowCallback } from './components/pop-up/ModalWindowCallback';
//import { Reviews } from './pages/main/Reviews';
import { WindowSize } from './hooks/WindowSize';
import { ModalWindowThanks } from './components/pop-up/ModalWindowThanks';
import { ModalWindow3ndfl } from './components/pop-up/ModalWindow3ndfl';
import { Preloader } from './components/Preloader';
import FaqAdmin from './pages/main/FaqAdmin';
import { useAppDispatch, useAppSelector } from './redux/redux-store';
import { useCurrentTheme } from './hooks/useCurrentTheme';
import { initialFontSize, useCurrentFontSize } from './hooks/useCurrentFontSize';
import { useToggleVisuallyImpairedPanel } from './hooks/useToggleVisuallyImpairedPanel';
import { News_9 } from './pages/main/news/current-news/News_9';
//import Gordeeva from './pages/main/doctors/Gordeeva';

const Prices = lazy(() => import('./pages/main/prices/Prices'));
const Reviews = lazy(() => import('./pages/main/Reviews'));
const Tour = lazy(() => import('./pages/main/Tour'));
const Timetable = lazy(() => import('./pages/main/time-table/Timetable'));
const Faq = lazy(() => import('./pages/main/Faq'));
const AllDoctors = lazy(() => import('./pages/main/AllDoctors'));
const Gordeeva = lazy(() => import('./pages/main/doctors/Gordeeva'));

const News = lazy(() => import('./pages/main/news/News'));


//const initialState = {show: false, translateY: "-58px"};

function App() {

  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state);
  const windowWidth = WindowSize();

  
  const { themeName, setThemeName, handleToggleTheme } = useCurrentTheme();
  const { fontSize, setFontSize, handleFontSize } = useCurrentFontSize(); 
  const { visuallyImpairedPanel, handleVisuallyImpairedPanel } = useToggleVisuallyImpairedPanel(themeName, setThemeName, setFontSize); 
  //const [visuallyImpairedPanel, setVisuallyImpairedPanel] = useState(initialState); 
  const [scroll, setScroll] = useState(0);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  //window.localStorage.clear();

  // const handleVisuallyImpairedPanel = () => {        

  //   if(!visuallyImpairedPanel.show){
  //       window.localStorage.setItem("translateY", "0");
  //       window.localStorage.setItem("show", "true");
  //       setVisuallyImpairedPanel({
  //           ...visuallyImpairedPanel,
  //           show: true,
  //           translateY: "0"
  //       });
  //   }

  //   if(visuallyImpairedPanel.show) {

  //       if(themeName === "default") {
  //           window.localStorage.setItem("translateY", "-58px");
  //           window.localStorage.setItem("show", "false");
  //           window.localStorage.fontSize = initialFontSize;
  //       }
  //       else {
  //           window.localStorage.clear();
  //           setThemeName("default");
  //       }
  //       setFontSize(initialFontSize);
  //       setVisuallyImpairedPanel({
  //           ...visuallyImpairedPanel,
  //           show: false,
  //           translateY: "-58px"
  //       });
  //   }        
  // }

  // useEffect(() => { 
  //   const offset = window.localStorage.getItem('translateY');
  //   const panel = window.localStorage.getItem('show') === "true" ? true : false;
  //   offset && setVisuallyImpairedPanel({...visuallyImpairedPanel, translateY: offset, show: panel});
  // }, []);

   
  // Логика левого бокового меню
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const handleToggleSidebar = () => setIsOpenSidebar(prev => !prev);

  //Логика появления модальных окон
  const [isOpenModalWindow3ndfl, setIsOpenModalWindow3ndfl] = useState(false);
  const [isOpenModalWindowRecord, setIsOpenModalWindowRecord] = useState(false);
  const [isOpenModalWindowCallback, setIsOpenModalWindowCallback] = useState(false);
  const [isOpenModalWindowThanks, setIsOpenModalWindowThanks] = useState(false);
  const [isOpenMobileMenuContact, setIsOpenMobileMenuContact] = useState(false);
      

  const handleToggleModalWindow = (windowName?: string) => {    
    switch(windowName){
      case "3ndfl": 
        setIsOpenModalWindow3ndfl(prev => !prev);
        break;
      case "record": 
        setIsOpenModalWindowRecord(prev => !prev);
        break;
      case "callback":
        setIsOpenModalWindowCallback(prev => !prev);
        break;
      case "thanks":
        setIsOpenModalWindowThanks(prev => !prev);
        delete window.localStorage.nameBtn;
        break; 
      case "onlyMobileContact": 
        setIsOpenMobileMenuContact(prev => !prev);
        break;
      default:
        setIsOpenSidebar(prev => !prev);
    }
  }

  const handleSuccess = () => {
    setIsOpenModalWindow3ndfl(false);
    setIsOpenModalWindowRecord(false);
    setIsOpenModalWindowCallback(false);
    setIsOpenModalWindowThanks(true);
  }

  const handleError = () => {
    alert("Упс, что-то пошло не так..");  
  }
  
  useEffect(() => {
    const clientWidthStart = document.body.clientWidth;
    if(isOpenModalWindow3ndfl || isOpenModalWindowRecord || isOpenModalWindowCallback || isOpenMobileMenuContact || isOpenModalWindowThanks) {        
        document.body.style.width= "100%";
        document.body.style.overflowY = "hidden";
        const delta = document.body.clientWidth - clientWidthStart;        
        document.body.style.paddingRight = `${delta}px`;
    }
    return () => {        
        document.body.style.width= "";
        document.body.style.overflowY = "";
        document.body.style.paddingRight = "";
    }        
 }, [isOpenModalWindow3ndfl || isOpenModalWindowRecord || isOpenModalWindowCallback || isOpenMobileMenuContact || isOpenModalWindowThanks]);


  return (
    
    <ThemeProvider      
      theme={themeName === 'light' ? myTheme.light 
        : themeName === 'dark' ? myTheme.dark
        : themeName === 'blue' ? myTheme.blue
        : myTheme.default}          
    >
      <Sidebar 
        isOpenSidebar={isOpenSidebar}
        handleToggleSidebar={handleToggleSidebar}        
        sidebar={state.sidebar}
      />
      {isOpenSidebar && <Overlay />}
      {isOpenModalWindow3ndfl && 
        <ModalWindow3ndfl 
          handleToggleModalWindow={handleToggleModalWindow} 
          values={state.header.forms.ndfl.values}          
          onSuccess={handleSuccess}
          onError={handleError}
          scroll={scroll}
        />}
      
      {isOpenModalWindowRecord &&
        <>
          <Overlay />
          <ModalWindowRecord 
            handleToggleModalWindow={handleToggleModalWindow}
            onSuccess={handleSuccess}
            onError={handleError} 
            scroll={scroll}
          />
        </>
      }
      {isOpenModalWindowCallback && 
        <ModalWindowCallback 
          isOpenModalWindowCallback={isOpenModalWindowCallback}
          handleToggleModalWindow={handleToggleModalWindow} 
          onSuccess={handleSuccess}
          onError={handleError}
          scroll={scroll}
          //pageHeight={pageHeight}
        />
      }
      {isOpenModalWindowThanks && 
        <ModalWindowThanks 
          isOpenModalWindowThanks={isOpenModalWindowThanks}
          handleToggleModalWindow={handleToggleModalWindow}
          scroll={scroll}
          
        />
      }
      
      <Wrap offset={visuallyImpairedPanel.translateY}>
        
        <Header
          themeName={themeName}
          setThemeName={setThemeName}         
          handleToggleTheme={handleToggleTheme}
          fontSize={fontSize}
          handleFontSize={handleFontSize}
          handleVisuallyImpairedPanel={handleVisuallyImpairedPanel}
          visuallyImpairedPanel={visuallyImpairedPanel}
          handleToggleSidebar={handleToggleSidebar} 
          handleToggleModalWindow={handleToggleModalWindow} 
          sidebar={state.sidebar}
          windowWidth={windowWidth}   
          isOpenMobileMenuContact={isOpenMobileMenuContact}                   
        />
        
        <>        
          <Routes>
            <Route path="/" element = {
              <Suspense fallback={<Preloader />} >
                <Home 
                  homePage={state.homePage} 
                  themeName={themeName} 
                  fontSize={fontSize} 
                  dispatch={dispatch}
                  windowWidth={windowWidth} 
                />
              </Suspense>} 
            />

            <Route path="/doctors">
              <Route index element = {<Suspense fallback={<Preloader />}>
                <AllDoctors                  
                  fontSize={fontSize} 
                  windowWidth={windowWidth}
                />
              </Suspense>} />

              <Route path="gordeeva" element = {<Suspense fallback={<Preloader />}> 
                <Gordeeva                  
                  handleToggleModalWindow={handleToggleModalWindow}
                  windowWidth={windowWidth}    
                  fontSize={fontSize}         
                />
              </Suspense>} />
            </Route>
            

            <Route path="/news">
              <Route index element = {<Suspense fallback={<Preloader />}>
                <News                  
                  fontSize={fontSize} 
                  windowWidth={windowWidth}
                />
              </Suspense>} />

              <Route path="news_9" element = {<Suspense fallback={<Preloader />}> 
                <News_9
                  id={9}                      
                  fontSize={fontSize}                           
                />
              </Suspense>} />
            </Route>



            <Route path="/prices" element = {<Suspense fallback={<Preloader />}>
              <Prices 
                pricesPage={state.pricesPage}
                windowWidth={windowWidth}                
              />
              </Suspense>} 
            />
            <Route path="/reviews" element = {<Suspense fallback={<Preloader />}>
              <Reviews 
                reviewsPage={state.reviewsPage} 
                windowWidth={windowWidth}               
                dispatch={dispatch}
              />
              </Suspense>} 
            />

            <Route path="/timetable" element = {<Suspense fallback={<Preloader />}>
              <Timetable 
                timeTablePage={state.timeTablePage}                
                fontSize={fontSize}
              />
              </Suspense>} 
            />

            <Route path="/3d-tour" element = {<Suspense fallback={<Preloader />}>
              <Tour />
              </Suspense>} 
            />

            {/* <Route path="/faq" element = {<Suspense fallback={<Preloader />}>
              <Faq 
                faqPage={state.faqPage} 
                setIsOpenModalWindowThanks={setIsOpenModalWindowThanks} 
                dispatch={dispatch}
              />
              </Suspense>}            
            />

            <Route path="/66d8-8a1e-b4a7-19316ed8e635" element = {<Suspense fallback={<Preloader />}>
              <FaqAdmin
                faqPage={state.faqPage}
                setIsOpenModalWindowThanks={setIsOpenModalWindowThanks}
                dispatch={dispatch}
              />
            </Suspense>}
            />

            <Route path="/about" element = {<Suspense fallback={<Preloader />}>
              <About                
                windowWidth={windowWidth}    
                fontSize={fontSize}
              />
            </Suspense>}/>  */}

            

            <Route path="/eco" element = {<Eco />} />            
            <Route path="/contacts" element = {<Contacts />} />
                        
            {/* <Route path="/news" element = {<News />} /> */}
            <Route path="/usefulInfo" element = {<UsefulInformation />} />
            <Route path="/questionnaire" element = {<Questionnaire />} />
            <Route path="/documents" element = {<Documents />} />
            <Route path="/vacancy" element = {<Vacancy />} />
            <Route path="/reception-of-citizens" element = {<Reception />} />
            
            <Route path="/payment-terms" element = {<PaymentTerms />} />
          </Routes>           
        </>

        <Footer
          themeName={themeName}
          fontSize={fontSize}
          windowWidth={windowWidth}
          handleToggleModalWindow={handleToggleModalWindow} 
        />

        <GlobalStyles fontSize={fontSize}/>
      </Wrap>
    </ThemeProvider>
   
  );
}

type WrapPropsType = {    
  offset:string
}

const Wrap = styled.div<WrapPropsType>`
  transform: translateY(${props => props.offset}); 
  transition: transform 0.5s ease-in-out;  
  
  /* @media ${({theme}) => theme.media.mobile} {
    overflow-x: hidden;
  } */
`

export default App;
