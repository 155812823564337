import { RootState } from "../redux/redux-store";

export const headerSelector = (state:RootState) => state.header;
export const footerSelector = (state:RootState) => state.footer;
 
export const allDoctorsPageSelector = (state:RootState) => state.allDoctorsPage;
export const gordeevaPageSelector = (state:RootState) => state.gordeevaPage;
export const doctorsSelector = (state:RootState) => allDoctorsPageSelector(state).doctors;

export const homePageSelector = (state:RootState) => state.homePage;
export const aboutPageSelector = (state:RootState) => state.aboutPage;
export const newsPageSelector = (state:RootState) => state.newsPage;
