import { applyMiddleware, combineReducers, legacy_createStore as createStore } from 'redux';
import thunk from 'redux-thunk';
import { homeReducer } from "./homeReducer";
import { headerReducer } from "./headerReducer";
import { footerReducer } from "./footerReducer";
import { sidebarReducer } from "./sidebarReducer";
import { pricesReducer } from "./pricesReducer";
import { reviewsReducer } from "./reviewsReducer";
import { timeTableReducer } from "./timeTableReducer";
import { faqReducer as faqPageReducer } from "./faqReducer";
import { gordeevaReducer } from "./doctors/gordeevaReducer";
import { allDoctorsReducer } from "./allDoctorsReducer";
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { faqAdminReducer } from './slices/faqAdminSlice';
import { faqReducer } from './slices/faqSlice';
import { aboutReducer } from './aboutReducer';
import { newsReducer } from './newsReducer';


export const store = configureStore({
    reducer: {
        header: headerReducer,
        footer: footerReducer,
        sidebar: sidebarReducer,
        homePage: homeReducer,
        pricesPage: pricesReducer,
        reviewsPage: reviewsReducer,
        timeTablePage: timeTableReducer,
        aboutPage: aboutReducer,        
        gordeevaPage: gordeevaReducer,
        allDoctorsPage: allDoctorsReducer,
        faqPage: faqPageReducer,
        faqAdmin: faqAdminReducer,
        faq: faqReducer,
        newsPage: newsReducer        
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

//
// const rootReducer = combineReducers({
//
// })

// type rootReducerType = typeof rootReducer;
// export type AppStateType = ReturnType<rootReducerType>;
//
// //let state: AppStateType;
//
// export const store = createStore(rootReducer, applyMiddleware(thunk as any));
//
// export type StoreType = typeof store;
// export type AppDispatch = typeof store.dispatch;

//@ts-ignore
window.store = store;